import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'
import { FlexboxGrid, Loader } from 'rsuite'

import { ResourceTag, ScreenSize } from '../../utils/constants'
import { Catalog, Product } from '../../utils/types/Product'
import { useApi } from '../../services/useApi'
import useIsOnMobile from '../../services/useIsOnMobile'
import ArrowLink from '../ArrowLink'
import ProductsWithCarousel from '../ProductsWithCarousel'
import useGetAnalyticsLocation from '../../services/analytics/useGetAnalyticsLocation'

import styles from '../../styles/CategorySpecificCarousel.module.less'

interface CategorySpecificCarouselProps {
  categories: Catalog[]
  className?: string
  isFor404Page?: boolean
}

const CategorySpecificCarousel: FC<CategorySpecificCarouselProps> = (props) => {
  const { categories, className = '', isFor404Page } = props

  const { t } = useTranslation('products')
  const { getResource } = useApi()
  const analyticsLocation = useGetAnalyticsLocation('category_products_carousel')

  const isScreenSm = useIsOnMobile(ScreenSize.sm)
  const isScreenMd = useIsOnMobile(ScreenSize.md)

  // Only include top 3 category levels
  const filteredCategories = categories.filter((category) => Number(category?.['catalog.level']) < 4)

  const parentCategory = filteredCategories.at(0)
  const selectedCategory = filteredCategories.at(-1)
  const categoryId = selectedCategory?.id
  const categoryCode = selectedCategory?.['catalog.code']
  const categoryName = selectedCategory?.['catalog.label']

  const headerText = categoryId ? t('Browse similar products') : `${t('Featured')} ${t('products')}`
  const linkText = t(categoryId ? 'Browse in category {{ category }}' : 'Browse all products', { category: categoryName })
  const browseProductsUrl = categoryId ? `/products/category/${parentCategory?.['catalog.code']}/${categoryCode}` : '/products'

  const query = `include=attribute,media,price,product,product/property,text,catalog,supplier,supplier/service,attribute/property,stock&filter[f_catid]=${categoryId}`
  const {
    data: products,
    isLoading,
  } = getResource<Product>(categoryId ? ResourceTag.product : null, query)

  if (isLoading) {
    return (
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item>
          <Loader
            content="Loading products..."
            className="margin-top-spacer-tripple margin-bottom-spacer-half"
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    )
  }

  if (products.length === 0 || !products[0]?.media?.length) {
    return null
  }

  const image = products[0].media?.[0]

  return (
    <div
      data-testid="category-specific-carousel"
      className={`${styles['product-carousel']} ${className}`}
    >
      <FlexboxGrid
        justify="space-between"
        className="align-baseline margin-bottom-spacer-double"
      >
        <h2 className="h4 margin-bottom-zero">{isScreenMd ? t('landing:Top sellers with wholesale prices') : headerText}</h2>
        {!isScreenSm && (
          <FlexboxGrid.Item>
            <ArrowLink
              href={browseProductsUrl}
              text={linkText}
              bold={false}
            />
          </FlexboxGrid.Item>
        )}
      </FlexboxGrid>
      <ProductsWithCarousel
        products={products}
        browseProductsUrl={browseProductsUrl}
        categoryName={categoryName}
        imgUrl={isFor404Page ? undefined : image}
        analyticsContext={analyticsLocation}
      />
    </div>
  )
}

export default CategorySpecificCarousel
