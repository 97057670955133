import React, { FC } from 'react'

import { PRODUCTS_PER_SUPPLIER } from '../utils/constants'
import { Product, ProductMedia } from '../utils/types/Product'
import ProductCard from './Catalog/ProductCatalog/ProductCard'
import SeeAllCard from './Catalog/ProductCatalog/SeeAllCard'
import Carousel from './Layouts/Carousel'
import { AnalyticsContext } from '../utils/types/analytics'

import styles from '../styles/CategorySpecificCarousel.module.less'

interface ProductsWithCarouselProps {
  products: Product[]
  browseProductsUrl: string
  imgUrl?: ProductMedia
  categoryName?: string
  analyticsContext: AnalyticsContext
}

const ProductsWithCarousel: FC<ProductsWithCarouselProps> = ({
  products,
  browseProductsUrl,
  imgUrl,
  categoryName,
  analyticsContext,
}) => (
  <div className={styles['products-wrapper']}>
    <Carousel>
      {products
        ?.slice(0, PRODUCTS_PER_SUPPLIER)
        ?.map((product) => (
          <ProductCard
            product={product}
            key={product['product.code']}
            analyticsContext={analyticsContext}
          />
        ))}
      <SeeAllCard
        middleImg={imgUrl}
        href={browseProductsUrl}
        categoryOrSupplierName={categoryName}
      />
    </Carousel>
  </div>
)

export default ProductsWithCarousel
